// Core
import React, { useState, useEffect } from "react"
import { isMobile } from "react-device-detect"

// Components
import SEO from "../../components/seo"
import InternalHeader from "../../components/internalHeader"
import Pagination from "../../components/pagination"
import Scroll from "../../components/locomotiveScroll"
import ScrollSection from "../../components/locomotiveScroll/scrollSection"
import WideBtn from "../../components/wideBtn"
import { setCursorState } from "../../components/cursor/set"

// Style
import styles from "./credits.module.scss"
import "../../components/locomotiveScroll/locomotive-scroll.css"

// Assets
import Dot from "./assets/dot.png"
import BlogBtnArrow from "./assets/blog-arrow.inline.svg"
import NestaLogo from "./assets/logo.inline.svg"
import ToyFightLogo from "./assets/tf.inline.svg"
import LeftTree from "./assets/left-tree.png"
import RightTree from "./assets/right-tree.png"
import Twitter from "./assets/twitter.png"
import Instagram from "./assets/ig.png"
import Email from "./assets/email.png"
import House1 from "./assets/mobile-house.png"
import House2 from "./assets/desktop-house.png"
import TextLogo from "../assets/text-logo.png"

const pageData = {
  heading: "Credits",
}

const Credits = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
    }, 1000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
    }
  }, [])

  return (
    <div
      className={`${styles.credits} ${reveal ? styles.reveal : ``} scroller`}
    >
      <SEO title={pageData.heading} />
      <InternalHeader index={9} leaveEvent={setReveal} credits={true} />
      <img
        className={styles.leftTree}
        src={LeftTree}
        alt={`Tree with squirrel`}
        width="309"
        height="490"
      />
      <img
        className={styles.rightTree}
        src={RightTree}
        alt={`Tree`}
        width="225"
        height="369"
      />
      {/* <Pagination index={9} /> */}
      {!isMobile && <Scroll />}
      <div className={styles.content}>
        <ScrollSection>
          <div className={`${styles.inner} ${styles.heading}`}>
            <h1>Let’s talk!</h1>
            <p>
              To talk to us about this project or our work on a Sustainable
              Future, and to share your visions of a sustainable Scotland,
              contact us at{" "}
              <a
                onMouseEnter={() => setCursorState("pointer", true)}
                onMouseLeave={() => setCursorState("pointer", false)}
                href="mailto:scotland@nesta.org.uk"
              >
                scotland@nesta.org.uk
              </a>
            </p>
            <div className={`${styles.dots} flex flex--center`}>
              <img src={Dot} alt="Seperator" width="10" height="11" />
              <img src={Dot} alt="Seperator" width="10" height="11" />
              <img src={Dot} alt="Seperator" width="10" height="11" />
            </div>
          </div>
          <div className={styles.inner}>
            <h1>
              Thank you to everyone whose ideas and vision helped shape this
              project:
            </h1>
            <ul>
              <li>
                Jamie Brogan,{" "}
                <strong>Edinburgh Centre for Carbon Innovation</strong>
              </li>
              <li>
                Josiah Lockhart, <strong>Firstport</strong>
              </li>
              <li>
                Daisy Ford-Downes, <strong>Zebras Unite / Ventures Lab</strong>
              </li>
              <li>
                Helen Teeling,{" "}
                <strong>Glasgow City of Science and Innovation</strong>
              </li>
              <li>
                John Fitzgerald, <strong>SCVO</strong>
              </li>
              <li>
                Julie Proctor, <strong>GreenSpace Scotland</strong>
              </li>
              <li>
                Jo Pike, <strong>Scottish Wildlife Trust</strong>
              </li>
              <li>
                Pippa Coutts, <strong>Carnegie UK Trust</strong>
              </li>
              <li>
                Douglas Morrison,{" "}
                <strong>Construction Scotland Innovation Centre</strong>
              </li>
              <li>
                Douglas Peedle, <strong>Scottish Wildlife Trust</strong>
              </li>
              <li>
                Catriona Patterson, <strong>Creative Carbon Scotland</strong>
              </li>
              <li>Chris Creegan</li>
              <li>
                Eleanor Tucker, <strong>Sharing Economy UK</strong>
              </li>
              <li>
                Rachel Cowper, <strong>Inspiring Scotland</strong>
              </li>
              <li>
                Dr Siobhán Jordan, <strong>Interface</strong>
              </li>
              <li>
                Donagh Horgan, <strong>The Institute for Future Cities</strong>
              </li>
              <li>
                Diane Harbison, <strong>The Data Lab</strong>
              </li>
              <li>
                Theresa Swayne,{" "}
                <strong>Highlands and Islands Enterprise</strong>
              </li>
            </ul>
          </div>
        </ScrollSection>
        <ScrollSection>
          <div className={styles.inner}>
            <h2>Created by</h2>
            <a
              href="https://www.nesta.org.uk/project/nesta-scotland/"
              target="_blank"
              rel="noreferrer"
              className={`${styles.logo} ${styles.nestaLogo}`}
            >
              <NestaLogo />
              <span>in Scotland</span>
            </a>
            <ul>
              <li>Kyle Usher</li>
              <li>Jessica Clark</li>
              <li>Grant Collinson</li>
              <li>Adam Lang</li>
            </ul>
            <h2>Produced by</h2>
            <a
              href="https://toyfight.co"
              target="_blank"
              rel="noreferrer"
              className={`${styles.logo} ${styles.tfLogo}`}
            >
              <ToyFightLogo />
            </a>
            <ul>
              <li>Anastasiia Vinnichenko</li>
              <li>Brad Arnett</li>
              <li>Jonny Lander</li>
              <li>Leigh Whipday</li>
              <li>Luke Willetts</li>
            </ul>
            <h2>Illustrations by</h2>
            <ul>
              <li>Anastasiia Vinnichenko</li>
            </ul>
          </div>
        </ScrollSection>
        <ScrollSection>
          <div className={styles.inner}>
            <h2>Narrated by</h2>
            <ul>
              <li>Cat Hepburn</li>
            </ul>
            <h2>Build by</h2>
            <ul>
              <li>Brad Arnett</li>
            </ul>
            <h2>Words by</h2>
            <ul>
              <li>Grant Collinson</li>
            </ul>
            <h2>Conceived by</h2>
            <ul>
              <li>Kyle Usher</li>
            </ul>
            <div className={styles.blogInfo}>
              <img
                src={TextLogo}
                height={151}
                width={284}
                className={styles.textLogo}
              />
              <h3>Shared visions of Scotland’s sustainable future</h3>
              <p>Discover more about the project and how it was developed:</p>
              <WideBtn
                url="https://www.nesta.org.uk/project/future-visions-green-economic-recovery/"
                label="Read our blog"
                width={161}
              >
                <span>Read our blog</span>
                <BlogBtnArrow />
              </WideBtn>
            </div>
          </div>
        </ScrollSection>
        <ScrollSection>
          <div className={styles.inner}>
            <div className={styles.companyInfo}>
              <div className={styles.bottomLogo}>
                <NestaLogo />
              </div>
              <p>
                <strong>© 2020 Nesta</strong>
                <br />
                Nesta is a registered charity in England and Wales 1144091 and
                Scotland SC042833. Our main address is 58 Victoria Embankment,
                London, EC4Y 0DS.
              </p>
              <ul className={styles.socialLinks}>
                <li>
                  <a
                    href="https://twitter.com/Nesta_Scotland"
                    aria-label="Nesta Scotland Twitter"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.socialLink}
                    onMouseEnter={() => setCursorState("pointer", true)}
                    onMouseLeave={() => setCursorState("pointer", false)}
                  >
                    <img src={Twitter} alt="" height={17} width={20} />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nesta_uk/"
                    aria-label="Nesta UK Instagram"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.socialLink}
                    onMouseEnter={() => setCursorState("pointer", true)}
                    onMouseLeave={() => setCursorState("pointer", false)}
                  >
                    <img src={Instagram} alt="" height={19} width={19} />
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:scotland@nesta.org.uk"
                    aria-label="Nesta Scotland Email"
                    target="_blank"
                    rel="noreferrer"
                    className={styles.socialLink}
                    onMouseEnter={() => setCursorState("pointer", true)}
                    onMouseLeave={() => setCursorState("pointer", false)}
                  >
                    <img src={Email} alt="" height={14} width={20} />
                  </a>
                </li>
              </ul>
            </div>

            <div className={styles.replay}>
              <p className={`${styles.heading} h1`}>Replay?</p>
              <WideBtn
                to={"/"}
                label="Explore again"
                width={178}
                height={56}
                fontSize={18}
                outline={true}
              >
                <span>Explore again</span>
              </WideBtn>
            </div>
          </div>
        </ScrollSection>
        <ScrollSection>
          <div className={styles.houses}>
            <img
              className={`${styles.house} ${styles.houseMobile}`}
              src={House1}
              alt={`Coloured Houses`}
              width="375"
              height="139"
            />
            <img
              className={`${styles.house} ${styles.houseDesktop}`}
              src={House2}
              alt={`Coloured Houses`}
              width="1800"
              height="363"
            />
          </div>
        </ScrollSection>
      </div>
    </div>
  )
}

export default Credits
