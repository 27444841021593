import { useEffect } from "react"

import LocomotiveScroll from "locomotive-scroll"

import { scroll } from "./../../theme"

const Scroll = callbacks => {
  useEffect(() => {
    let locomotiveScroll

    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector(scroll.container),
      ...scroll.options,
    })

    window.scroll = locomotiveScroll
    locomotiveScroll.stop()
    update()

    locomotiveScroll.on("scroll", func => {
      document.documentElement.setAttribute("data-direction", func.direction)
    })

    setTimeout(begin, 500)

    return () => {
      if (window.scroll.smooth) {
        window.scroll.destroy()
      }
    }
  }, [callbacks])

  const begin = () => {
    update()
    window.scroll.start()
  }

  const update = () => {
    window.scroll.update()
  }

  return null
}

export default Scroll