export const scroll = {
  container: ".site-wrap",
  options: {
    smooth: true,
    smoothMobile: false,
    direction: "vertical",
    getDirection: true,
    touchMultiplier: 2.5,
    firefoxMultiplier: 50,
    lerp: 0.1,
    repeat: true
  },
}
